<template>
  <gf-base>
    <gf-card :title="title" :subtitle="subtitle" v-loading="loading">
      <template #toolbar>
        <gf-button type="secondary" @click="back">
          <template slot="icon">
            <span class="svg-icon svg-icon-sx">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24"/>
                  <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                  <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                </g>
              </svg>
            </span>
          </template>
          Back
        </gf-button>
      </template>
      <gf-container>
        <gf-form>
          <template #alert>
            <gf-alert>
              <template #icon>
                <span class="svg-icon svg-icon-primary svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "/>
                      <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    </g>
                  </svg>
                </span>
              </template>
              Input Company Details on the Form Below
            </gf-alert>
          </template>
          <el-form label-width="200px" size="medium" :model="company" :rules="companyRules" ref="company">
            <el-form-item label="Active" prop="is_active"  v-if="$route.meta.mode === 'update'">
              <el-switch v-model="company.is_active" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="Company Name" prop="name">
              <el-input @focus="helpIndex = 0" v-model="company.name"></el-input>
            </el-form-item>
            <el-form-item label="Company Type" v-if="type === 'customer'" >
              <el-select @focus="helpIndex = 1" style="width: 100%" v-model="company.type">
                <el-option :value="1" label="Business Customer"></el-option>
                <el-option :value="2" label="Consumer"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Company Code">
              <el-input @focus="helpIndex = 2" placeholder="e.g. ABC123" v-model="company.code"></el-input>
            </el-form-item>
            <el-form-item label="Tax Number">
              <el-input @focus="helpIndex = 3" placeholder="e.g. ESX9999999X" v-model="company.tax_number"></el-input>
            </el-form-item>
            <el-form-item label="Company Phone Number">
              <el-input @focus="helpIndex = 4" v-model="company.phone_number"></el-input>
            </el-form-item>
            <el-form-item label="Company Fax Number">
              <el-input @focus="helpIndex = 5" v-model="company.fax_number"></el-input>
            </el-form-item>
            <el-form-item label="Company Website">
              <el-input @focus="helpIndex = 6" v-model="company.website"></el-input>
            </el-form-item>
            <el-form-item label="Company Email Address">
              <el-input @focus="helpIndex = 7" v-model="company.email"></el-input>
            </el-form-item>
            <el-form-item label="Description">
              <el-input type="textarea" :rows="2" v-model="company.description"></el-input>
            </el-form-item>
            <gf-alert class="mb-8">
              <template #icon>
                <span class="svg-icon svg-icon-primary svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" fill="#000000"/>
                    </g>
                  </svg>
                </span>
              </template>
              Set defaults setting such which will be used when creating Sales Orders / Purchase Order for this company
            </gf-alert>
            <el-form-item label="Customer Group" v-if="type === 'customer'">
              <el-select @focus="helpIndex = 8" style="width: 100%" v-model="company.setting[0].customer_group_id" filterable remote :remote-method="getCustomerGroups" :loading="customerGroupLoading" @change="selectCustomerGroup">
                <el-option :value="null" label="Unassign"></el-option>
                <el-option v-for="cg in customerGroups" :key="cg.id" :value="cg.id" :label="cg.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Default Price List">
              <el-select @focus="helpIndex = 9" style="width: 100%" v-model="company.setting[0].price_list_id" :disabled="isCustomerGroup">
                <el-option v-for="pl in priceLists" :key="pl.id" :value="pl.id" :label="pl.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Default Discount" v-if="type === 'customer'">
              <el-input @focus="helpIndex=10" v-model="company.setting[0].discount" :disabled="isCustomerGroup">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
            <el-form-item label="Default Payment Term" v-if="type === 'customer'">
              <el-select @focus="helpIndex = 11" style="width: 100%" v-model="company.setting[0].payment_term_id" :disabled="isCustomerGroup">
                <el-option v-for="pt in paymentTerms" :key="pt.id" :value="pt.id" :label="pt.name"></el-option>
              </el-select>
            </el-form-item>
            <br v-if="type === 'customer'">
            <el-form-item label="Default Payment Method" v-if="type === 'customer'">
              <el-select @focus="helpIndex = 14" style="width: 100%" v-model="company.setting[0].payment_method_id">
                <el-option v-for="pm in paymentMethods" :key="pm.id" :value="pm.id" :label="pm.name"></el-option>
              </el-select>
            </el-form-item>
            <div v-if="$route.meta.mode === 'create'">
              <gf-alert class="mb-8">
                <template #icon>
                  <span class="svg-icon svg-icon-primary svg-icon-md">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000"/>
                      </g>
                    </svg>
                  </span>
                </template>
                Add an address to this company, for use in your Sales Orders, invoices and Purchase Orders
              </gf-alert>
              <el-form-item label="Address Name" prop="address.0.label" :rules="{ required: true, message: 'Address Name can\'t be blank', trigger: 'blur' }">
                <el-input @focus="helpIndex = 15" v-model="company.address[0].label" placeholder="eg. Shipping Address, Billing Address"></el-input>
              </el-form-item>
              <el-form-item label="Address Line 1" prop="address.0.address_line_1" :rules="{ required: true, message: 'Address Line 1 can\'t be blank', trigger: 'blur' }">
                <el-input @focus="helpIndex = 16" v-model="company.address[0].address_line_1"></el-input>
              </el-form-item>
              <el-form-item label="Address Line 2">
                <el-input @focus="helpIndex = 16" v-model="company.address[0].address_line_2"></el-input>
              </el-form-item>
              <el-form-item label="City">
                <el-input @focus="helpIndex = 16" v-model="company.address[0].city"></el-input>
              </el-form-item>
              <el-form-item label="Zip Code">
                <el-input @focus="helpIndex = 16" v-model="company.address[0].zip_code"></el-input>
              </el-form-item>
              <br>
              <el-form-item label="Email Address">
                <el-input @focus="helpIndex = 16" v-model="company.address[0].email"></el-input>
              </el-form-item>
              <el-form-item label="Phone Number">
                <el-input @focus="helpIndex = 16" v-model="company.address[0].phone_number"></el-input>
              </el-form-item>
            </div>
          </el-form>
          <template #footer>
            <gf-button type="primary" @click="submit">
              <template #icon>
                <span class="svg-icon svg-icon-white">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"/>
                      <path d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z" fill="#000000" fill-rule="nonzero"/>
                      <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="5" rx="0.5"/>
                    </g>
                  </svg>
                </span>
              </template>
              Save
            </gf-button>
          </template>
        </gf-form>
        <template #aside>
          <gf-helper :title="helpInfo[helpIndex].title" :content="helpInfo[helpIndex].content"></gf-helper>
        </template>
      </gf-container>
    </gf-card>
  </gf-base>
</template>

<script>
// Services
import CompanyService from '@/services/v1/Company'
import CustomerGroupService from '@/services/v1/CustomerGroup'
import PriceListService from '@/services/v1/PriceList'
import PaymentTermService from '@/services/v1/PaymentTerm'
import PaymentMethodService from '@/services/v1/PaymentMethod'

export default {
  data () {
    return {
      companyId: this.$route.params.id,

      // main
      company: {
        name: null,
        type: 1,
        code: null,
        tax_number: null,
        phone_number: null,
        fax_number: null,
        website: null,
        email: null,
        description: null,
        setting: [
          {
            customer_group_id: null,
            price_list_id: null,
            discount: null,
            payment_term_id: null,
            payment_method_id: null
          }
        ],
        address: [
          {
            label: null,
            address_line_1: null,
            address_line_2: null,
            city: null,
            zip_code: null,
            email: null,
            phone_number: null
          }
        ]
      },
      customerGroups: [],
      priceLists: [],
      paymentTerms: [],
      paymentMethods: [],

      // helpers
      type: 'customer',
      helpInfo: [
        {
          title: 'Create a New Relationship',
          content: 'Dekatan IMS allows you to create a unified directory of relationships including Business Customers, Consumers, and Suppliers via the Relationships Option.'
        },
        {
          title: 'Company Type',
          content: 'Business Customers refer to your wholesale customers or companies that operate their own retail businesses, while Consumers are customers that buy from you directly for their own consumption.'
        },
        {
          title: 'Company Code',
          content: 'Assigning a Company Code makes it easier to reference this relationship in the future.'
        },
        {
          title: 'Tax Number',
          content: 'Indicate the customer / business / supplier\'s tax number.'
        },
        {
          title: 'Company Phone Number',
          content: 'Add the customer / business / supplier\'s office phone number.'
        },
        {
          title: 'Company Fax Number',
          content: 'Add the customer / business / supplier\'s office fax number.'
        },
        {
          title: 'Company Website',
          content: 'Add the customer / business / supplier\'s official website.'
        },
        {
          title: 'Company Email Address',
          content: 'Add the company\'s email address.'
        },
        {
          title: 'Customer Groups',
          content: 'Select the default group the company belongs to.'
        },
        {
          title: 'Default Price List',
          content: 'Set the Default Price List that will be used when creating Sales or Purchase Orders for this company.'
        },
        {
          title: 'Default Discount',
          content: 'Set the Default Discount Rate that will be used when creating Sales or Purchase Orders for this company.'
        },
        {
          title: 'Default Payment Term',
          content: 'Set the Default Payment Term that will be used when creating Sales or Purchase Orders for this company.'
        },
        {
          title: 'Minimum Order Value',
          content: 'Add a minimum order value that can be used on your B2B eCommerce Platform.'
        },
        {
          title: 'Default Stock Location',
          content: 'Set the Default Stock Location that will be used when creating Sales or Purchase Orders for this company.'
        },
        {
          title: 'Default Payment Method',
          content: 'Set the Default Payment Method that will be used when creating Sales or Purchase Orders for this company.'
        },
        {
          title: 'Address Name',
          content: 'A company may have a different Shipping Address from the Billing Address. In this case, you can name the corresponding addresses \'Shipping Address\' or \'Billing Address\'.'
        },
        {
          title: 'Complete Address',
          content: 'Fill out as much information as you can here as these will appear in documents such as Sales Orders, Invoices, and Purchase Orders.'
        }
      ],
      helpIndex: 0,
      isButtonLoading: false,
      buttonTitle: '',
      isCustomerGroup: false,

      // rules
      companyRules: {
        name: [
          { required: true, message: 'Name is require', trigger: 'blur' }
        ]
      },

      // ui helpers
      title: '',
      subtitle: '',
      loading: false,
      customerGroupLoading: false
    }
  },
  methods: {
    // navigation
    back () {
      this.$router.back()
    },
    // main
    submit () {
      this.$refs.company.validate()
        .then(async () => {
          try {
            this.loading = true
            if (this.type === 'supplier') this.company.type = 3

            const company = this.$_.cloneDeep(this.company)

            if (company.setting[0].customer_group_id) {
              delete company.setting[0].price_list_id
              delete company.setting[0].discount
              delete company.setting[0].payment_term_id
            }

            this.$Sanitize(company)
            this.$Sanitize(company.setting[0])
            this.$Sanitize(company.address[0])

            if (this.isEmpty(company.setting[0])) delete company.setting
            if (this.isEmpty(company.address[0])) delete company.address

            let id = this.companyId
            if (this.$route.meta.mode === 'create') {
              const cmService = new CompanyService()
              id = (await cmService.create(company)).data.id
              this.$message.success(`Company ${company.name} was successfully created`)
            } else {
              const cmService = new CompanyService(this.companyId)
              await cmService.update(company)
              this.$message.success(`Company ${company.name} was successfully updated`)
            }

            this.$router.push({ name: 'company-view', params: { id } })
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },

    // Supporting method
    selectCustomerGroup () {
      if (!this.company.setting[0].customer_group_id) {
        this.isCustomerGroup = false
        this.company.setting[0].price_list_id = null
        this.company.setting[0].discount = null
        this.company.setting[0].payment_term_id = null
        return
      }

      // get customer groups
      const customerGroup = this.customerGroups.find(cg => cg.id === this.company.setting[0].customer_group_id)
      this.isCustomerGroup = true
      this.company.setting[0].price_list_id = customerGroup.price_list_id
      this.company.setting[0].discount = customerGroup.discount_rate
      this.company.setting[0].payment_term_id = customerGroup.payment_term_id
    },

    // Fetch
    async getCompany () {
      try {
        this.loading = true
        const cmService = new CompanyService(this.companyId)
        const result = await cmService.get()

        this.company.is_active = result.data.is_active
        this.company.name = result.data.name
        this.company.type = result.data.type
        this.company.code = result.data.code
        this.company.tax_number = result.data.tax_number
        this.company.phone_number = result.data.phone_number
        this.company.fax_number = result.data.fax_number
        this.company.website = result.data.website
        this.company.email = result.data.email
        this.company.description = result.data.description

        if (result.data.setting.length) {
          if (this.company.type === 3) {
            this.company.setting[0].price_list_id = result.data.setting[0].price_list_id
          } else {
            this.company.setting[0].customer_group_id = result.data.setting[0].customer_group_id

            if (!this.company.setting[0].customer_group_id) {
              this.isCustomerGroup = false
              this.company.setting[0].price_list_id = result.data.setting[0].price_list_id
              this.company.setting[0].discount = result.data.setting[0].discount
              this.company.setting[0].payment_term_id = result.data.setting[0].payment_term_id
            } else {
              await this.getCustomerGroups(result.data.setting[0].customer_group.name)
              const cg = this.customerGroups.find(cg => cg.id === this.company.setting[0].customer_group_id)

              this.isCustomerGroup = true
              this.company.setting[0].price_list_id = cg.price_list_id
              this.company.setting[0].discount = cg.discount_rate
              this.company.setting[0].payment_term_id = cg.payment_term_id
            }

            this.company.setting[0].payment_method_id = result.data.setting[0].payment_method_id
          }
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async getCustomerGroups (query) {
      try {
        const filter = {}
        this.customerGroupLoading = true
        if (query === '' || !query) {
          delete filter.q
        } else {
          filter.q = {
            like: query
          }
        }

        const cgService = new CustomerGroupService()
        const result = await cgService.list(null, null, filter)

        this.customerGroups = result.data.rows
      } catch (error) {
        this.$Error(error)
      } finally {
        this.customerGroupLoading = false
      }
    },
    async getPriceLists () {
      try {
        const plService = new PriceListService()
        const result = await plService.list()
        this.priceLists = result.data.rows
      } catch (error) {
        this.$Error(error)
        this.$route.back()
      }
    },
    async getPaymentTerms () {
      try {
        const ptService = new PaymentTermService()
        const result = await ptService.list()
        this.paymentTerms = result.data.rows
      } catch (error) {
        this.$Error(error)
        this.$route.back()
      }
    },
    async getPaymentMethods () {
      try {
        const pmService = new PaymentMethodService()
        const result = await pmService.list()
        this.paymentMethods = result.data.rows
      } catch (error) {
        this.$Error(error)
        this.$route.back()
      }
    },
    isEmpty (obj) {
      return Object.keys(obj).length === 0
    }
  },
  async mounted () {
    this.loading = true

    await this.getPriceLists()
    await this.getPaymentTerms()
    await this.getPaymentMethods()

    if (this.$route.meta.mode === 'create') {
      this.type = this.$route.params.type
      this.title = 'New ' + this.type.charAt(0).toUpperCase() + this.type.slice(1)
      this.subtitle = 'Create new ' + this.type
      this.buttonTitle = 'Create Customer'
      await this.getCustomerGroups()

      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Relationship', route: '' },
        { title: 'Companies' },
        { title: 'Create' }
      ])

      this.loading = false
    } else if (this.$route.meta.mode === 'update') {
      await this.getCompany()
      this.type = this.company.type !== 3 ? 'customer' : 'supplier'
      this.title = this.company.name
      this.buttonTitle = 'Save Changes'

      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Relationship', route: '' },
        { title: 'Companies' },
        { title: this.title },
        { title: 'Update' }
      ])
    }
  }
}
</script>
